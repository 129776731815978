<template>
	<div class="w-100  bg-white rounded shadow p-2" style="min-height:50vh" v-loading.fullscreen.lock="loading">
		<div class="flex justify-flex-end my-1">
			<el-tag type="primary" class="mx-1">
				请求：{{ (call_time.finished - call_time.start) / 1000 || 0}}ms
			</el-tag>
			<el-tag type="success"  class="mx-1">
				渲染：{{ (finished_time - start_time) / 1000 || 0}}ms
			</el-tag>
			<el-tag type="warning"  class="mx-1">
				展开：{{ (expend_time.finished - expend_time.start) / 1000 || 0}}ms
			</el-tag>
			<div class="mx-2">
				<el-button type="success" plain :icon="Refresh" size="small" @click="get_data"/>
			</div>
		</div>
		<el-tabs type="border-card">
			<el-tab-pane :label="t('Main')">
				<div>
					<div class="flex justify-flex-end my-1">
						<el-switch v-model="children_group_by_project" class="ml-2" size="large" inline-prompt
							active-text="按照项目分组" inactive-text="按照项目分组" @change="group_child_data" />
						<el-switch v-model="children_group_by_ss" class="ml-2" size="large" inline-prompt
							active-text="按照统计阶段分组" inactive-text="按照统计阶段分组" @change="group_child_data" />
					</div>
					<el-table :data="grouped_data.result" style="width: 100%" max-height="800" border
						@expand-change="log_expend_time"
						highlight-current-row stripe show-summary scrollbar-always-on :summary-method="getSummary">
						<el-table-column type="expand">
							<template #default="props">
								<div class="m-4">
									<div class="flex justify-center">
										<!-- <div>{{ t('Company') }}: {{ props.row.company }}</div> -->
										<el-tag type="primary">{{ t('Account') }}: {{ props.row.name }}</el-tag>
									</div>
									<el-table :data="props.row.children_filtered" highlight-current-row stripe
										show-summary :summary-method="getSummary" max-height="600">
										<el-table-column
											v-if="children_group_by_project || ((children_group_by_project && children_group_by_ss) || (!children_group_by_project && !children_group_by_ss))"
											:label="t('Project')" prop="project" sortable />
										<el-table-column
											v-if="children_group_by_ss || ((children_group_by_project && children_group_by_ss) || (!children_group_by_project && !children_group_by_ss))"
											:label="t('Statistical Stage')" prop="statistical_stage" sortable />
										<el-table-column :label="t('Debit')" prop="debit" align="right"
											sortable></el-table-column>
										<el-table-column :label="t('Credit')" prop="credit" align="right" sortable />
										<el-table-column :label="t('Balance')" prop="balance" align="right" sortable>
											<template v-slot="scope">
												<span>
													{{ getFormatter('Currency', scope.row['balance']) }}
												</span>
											</template>
										</el-table-column>
										<el-table-column v-for="col in grouped_data.cols" :key="col.value"
											:prop="col.value" :label="t(col.label)" fit sortable align="right">
											<template v-slot="scope">
												<span>
													{{ getFormatter('Currency', scope.row[col.value]) }}
												</span>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="name" :label="t('Account')" fit sortable width="240">
							<template v-slot="scope">
								<div>
									<el-link :href="get_url('Link', scope.row['name'], 'Account')" target="_blank">{{
										scope.row['name'] }}</el-link>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="abbr" :label="t('Abbr')" fit sortable :filters="abbr"
							:filter-method="filterHandler"></el-table-column>
						<!-- <el-table-column prop="statistical_stage" :label="t('Statistical Stage')" fit sortable :filters="ss" :filter-method="filterHandler"></el-table-column> -->
						<el-table-column prop="business_division" :label="t('Business Division')" fit sortable
							:filters="bds" :filter-method="filterHandler"></el-table-column>
						<el-table-column prop="openings" :label="t('Opening')" fit sortable align="right">
							<template v-slot="scope">
								<span>
									{{ getFormatter('Currency', scope.row['openings']) }}
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="balance" :label="t('Current Period')" fit sortable align="right">
							<template v-slot="scope">
								<span>
									{{ getFormatter('Currency', scope.row['balance']) }}
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="closing" :label="t('Closing Balance')" fit sortable align="right">
							<template v-slot="scope">
								<span>
									{{ getFormatter('Currency', scope.row['closing']) }}
								</span>
							</template>
						</el-table-column>
						<el-table-column v-for="col in grouped_data.cols" :key="col.value" :prop="col.value"
							:label="t(col.label)" fit sortable align="right">
							<template v-slot="scope">
								<span>
									{{ getFormatter('Currency', scope.row[col.value]) }}
								</span>
							</template>
						</el-table-column>


					</el-table>
				</div>
			</el-tab-pane>
			<el-tab-pane :label="t('Details')">
				<div class="flex">
					<el-switch v-model="show_gles" class="ml-2" size="large" inline-prompt :active-text="t('Show')"
						:inactive-text="t('Hide')" />
				</div>
				<div v-if="show_gles">
					<div>{{ t('Opening') }}</div>
					<el-table :data="openings" style="width: 100%" max-height="800" show-summary>
						<el-table-column fixed prop="account" :label="t('Account')" fit />
						<el-table-column prop="balance" :label="t('Balance')" fit />
					</el-table>
				</div>
				<div v-if="show_gles">
					<div>{{ t('gles') }}</div>
					<el-table :data="gles" style="width: 100%" max-height="800">
						<el-table-column fixed prop="account" :label="t('Account')" fit />
						<el-table-column prop="project" :label="t('Project')" fit show-overflow-tooltip width="240" />
						<el-table-column prop="balance" :label="t('Balance')" fit />
						<el-table-column prop="business_division" :label="t('Business Division')" fit />
						<el-table-column prop="account_business_division" :label="t('Account') + t('Business Division')"
							fit />
					</el-table>
				</div>

				<div></div>
				<div></div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick  } from 'vue';
import { ElLoading } from 'element-plus'
import { Delete, Edit, Search, Share, Upload, Refresh } from '@element-plus/icons-vue'
import {
	getFormatter, get_url, t, formatCurrency
} from '../base/vue_utils.js'
const res = reactive({
	data: [],
});
const loading = ref(null);
const accounts = ref([]);
const openings = ref([]);
const gles = ref([]);
const grouped_data = reactive({});
const show_gles = ref(false);
const abbr = ref([]);
const bds = ref([]);
const ss = ref([]);
const children_group_by_project = ref(false);
const children_group_by_ss = ref(false);
const start_time = ref()
const finished_time = ref()
const call_time = reactive({
	start: null,
	finished: null,
})
const expend_time = reactive({
	start: null,
	finished: null,
})
// const toggle_gles_display = () => {
// 	console.log(show_gles.value);
// 	show_gles.value = !show_gles.value;
// };
const get_data = () => {
	loading.value = true;
	call_time.start = new Date();
	frappe.call({
		method: "localization.localization.page.funds_distribution.funds_distribution.get_data",
		args: {},
		callback: (r) => {
			call_time.finished = new Date();
			if (r.message) {
				data = r.message;
				// console.log(data)
				accounts.value = data.accounts;
				openings.value = data.openings;
				gles.value = data.gles;
				grouped_data['cols'] = data.data.cols;
				grouped_data['result'] = data.data.result;
				// grouped_data['filtered_data'] = data.data.result;
				abbr.value = data.data.abbr;
				bds.value = data.data.bds;
				ss.value = data.data.ss;
				// console.log(grouped_data);
				nextTick(() => {
					finished_time.value = new Date();
					loading.value = false;
				})

			}
		}
	})
};

const getSummary = (param) => {
	const { columns, data } = param;
	const sums = [];
	let sum_cols = ['openings', 'balance', 'closing', 'debit', 'credit'];
	bds.value.forEach(bd => {
		sum_cols.push(bd.value);
	});

	columns.forEach((column, index) => {
		if (in_list(sum_cols, column.property)) {
			const values = data.map(item => {
				const value = item[column.property];
				const parsedValue = parseFloat(value); // 尝试将值转换为数字
				return Number.isNaN(parsedValue) ? 0 : parsedValue; // 如果转换后的值是NaN，则返回0，否则返回转换后的值
			});
			const sum = values.reduce((acc, val) => acc + val, 0);
			sums[index] = formatCurrency(sum); // 格式化货币值
		} else {
			sums[index] = '';
		}
	});
	return sums;
}

const filterHandler = (value, row, column) => {
	const property = column['property']
	return row[property] === value
}

const group_child_data = () => {
	loading.value = true;
	start_time.value = new Date();
	// 同时选择或者不选择
	if ((children_group_by_project.value && children_group_by_ss.value) || (!children_group_by_project.value && !children_group_by_ss.value)) {
		grouped_data['result'].forEach(item => {
			item.children_filtered = item.children
		})
		nextTick(() => {
			finished_time.value = new Date();
			loading.value = false;
		})
	} else {
		let group_by = children_group_by_project.value ? 'project' : 'statistical_stage';
		grouped_data['result'].forEach(item => {
			group_children(item, group_by)
		})
		nextTick(() => {
			finished_time.value = new Date();
			loading.value = false;
		})
	}


}

// const group_children = (data,group_by) => {
// 	if (!data.children) return
// 	let children = data.children
// 	// let data_cols = ['debit','credit','balance']
// 	// data_cols.push(fieldname)
// 	// bds.value.forEach(bd=>{
// 	// 	data_cols.push(bd.value)
// 	// })
// 	bd_list = bds.value.map(bd=> bd.value)
// 	// 初始化一个空对象用于分组和求和
// 	let groupedData = {};

// 	// 遍历列表并根据statistical_stage字段进行分组和求和
// 	children.forEach(item => {
// 		const stage = item[group_by];
// 		if (!groupedData[stage]) {
// 			groupedData[stage] = {
// 				account: item.account,
// 				debit: 0,
// 				credit: 0,
// 				balance: 0
// 			};
// 		}
// 		// 新增求和字段
// 		bd_list.forEach(bd=>{
// 			if (!groupedData[stage][bd]) {
// 				groupedData[stage][bd] = 0;
// 			}
// 			groupedData[stage][bd] += item[bd];
// 		})
// 		groupedData[stage].debit += item.debit;
// 		groupedData[stage].credit += item.credit;
// 		groupedData[stage].balance += item.balance;
// 	});

// 	// 将分组后的对象转换为数组
// 	const result = Object.entries(groupedData).map(([stage, values]) => ({
// 		[group_by]: stage,
// 		debit: values.debit,
// 		credit: values.credit,
// 		balance: values.balance
// 	}));

// 	data['children_filtered'] =  result;
// }
const group_children = (data, group_by) => {
	if (!data.children) return;

	const children = data.children;
	const bd_list = bds.value.map(bd => bd.value);

	// 初始化一个空对象用于分组和求和
	let groupedData = {};

	// 遍历列表并根据 group_by 字段进行分组和求和
	children.forEach(item => {
		const stage = item[group_by];
		if (!groupedData[stage]) {
			groupedData[stage] = {
				account: item.account,
				debit: 0,
				credit: 0,
				balance: 0,
				...bd_list.reduce((acc, bd) => ({ ...acc, [bd]: 0 }), {})
			};
		}

		// 更新分组中的求和字段
		groupedData[stage].debit += item.debit;
		groupedData[stage].credit += item.credit;
		groupedData[stage].balance += item.balance;
		bd_list.forEach(bd => {
			groupedData[stage][bd] += item[bd];
		});
	});

	// 将分组后的对象转换为数组，并包含新增的求和字段
	const result = Object.entries(groupedData).map(([stage, values]) => ({
		[group_by]: stage,
		debit: values.debit,
		credit: values.credit,
		balance: values.balance,
		...bd_list.reduce((acc, bd) => ({ ...acc, [bd]: values[bd] }), {})
	}));

	data['children_filtered'] = result;
};

const log_expend_time =(row, expandedRows) => {
	expend_time.start = new Date();
	// console.log(row, expandedRows, expend_time.start)
	nextTick(() => {
		expend_time.finished = new Date();
		// console.log(expend_time.end,expend_time.end - expend_time.start)
	})
}

onMounted(() => {
	get_data();
	start_time.value = new Date();
});
</script>
<style module>
@import "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";
</style>
<style scoped></style>