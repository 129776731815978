frappe.format_json_data = function(frm,field_name) {
	data = frm.doc[field_name];
	if (typeof data === 'string') {
		try {
			data = JSON.parse(data);
			frm.set_value(field_name, JSON.stringify(data, null, 4));
			frm.disable_save()
		} catch (e) {
			frappe.throw(__('Invalid JSON'));
		}
	}

}

frappe.show_uwl_dialog = function(data,currentTarget) {
	let fields = get_fields(data)
	var d = new frappe.ui.Dialog({
		title: __("Workflow"),
		size: "extra-large",
		fields:fields,
		primary_action:function() {
			d.hide()
		},
		primary_action_label: __('Hide'),
	})
	d.show()
	let element = d.$wrapper.find('#actions')[0];
	const btn_class_map = {
		'Approve': 'btn-primary',
		'Reject': 'btn-danger',
		'Cancelled': 'btn-danger',
		'Check Done': 'btn-primary',
		'Return': 'btn-warning',
		'提交财务': 'btn-primary',
		'财务复核': 'btn-primary',
		'Submit': 'btn-primary',
	}
	if (data.transactions) {
		data.transactions.forEach((item, index) => {
			let tempDiv = document.createElement('div');
			const buttonId = `action-btn-${index}`;
			tempDiv.innerHTML = `
			<div 
					class="btn btn-sm primary-action custom_btn ${btn_class_map[item.action]}" 
					style="min-width: 12rem; margin:10px;"
					id="${buttonId}"
				>
					${__(item.action)} => <span style="font-size:small">${item.next_state}</span>
				</div>
			`;
			element.appendChild(tempDiv);
		})
	
		// 为每个按钮添加事件监听器
		data.transactions.forEach((item, index) => {
			const button = element.querySelector(`#action-btn-${index}`);
			button.addEventListener('click', function() {
				apply_action(data, item.action,currentTarget);
			});
		});
	}
	function apply_action(data,action,el) {
		frappe.call({
			method:"frappe.model.workflow.apply_workflow",
			args:{
				doc:data.doc,
				action:action
			},
			callback:(res)=> {
				if (res.message) {
					frappe.show_alert({ message: __("Successfully Done"), indicator: "green" });
				}
				// 将焦点设置回触发对话框的按钮或其他合适的元素上
				if (currentTarget && currentTarget.focus) {
					currentTarget.focus();
				} else {
					// 作为备选方案，将焦点设置到body上
					document.body.focus();
				}
				// el.style.display = 'none';
				d.hide()
			}
		})
	}
}

function get_fields(data,element) {
	let doc = data.doc
	let child_fields = []
	let group_fields = []
	
	let fields = [
		{
			fieldtype: 'Data',
			fieldname: 'Company',
			label: __('Company'),
			default: doc.company,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'party',
			label: __('Party'),
			default: doc.customer || doc.supplier || doc.party || doc.employee_name || '',
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Date',
			fieldname: 'posting_date',
			label: __('Posting Date'),
			default: doc.posting_date || doc.transaction_date,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'project',
			label: __('Project'),
			default: doc.project || '',
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Currency',
			fieldname: 'grand_total',
			label: __('Grand Total'),
			default: doc.grand_total || doc.amount || doc.payment_amount || 0,
			read_only: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'expense_approver',
			label: __('Expense Approver'),
			default: doc.expense_approver || '',
			read_only: 1,
			depends_on:"eval:doc.expense_approver"
		},
		{
			fieldtype: 'Data',
			fieldname: 'invoice_status',
			label: __('Invoice Status'),
			default: doc.invoice_status || '',
			read_only: 1,
			depends_on:"eval:doc.expense_approver"
		},
		{
			fieldtype: 'Section Break'
		},
		{
			fieldtype: 'Datetime',
			fieldname: 'creation',
			label: __('Created On'),
			default: doc.creation,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Data',
			fieldname: 'owner',
			label: __('Owner'),
			default: doc.owner,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Datetime',
			fieldname: 'modified',
			label: __('Modified'),
			default: doc.modified,
			read_only: 1,
		},
		{
			fieldtype: 'Column Break'
		},
		{
			fieldtype: 'Data',
			fieldname: 'modified_by',
			label: __('Modified By'),
			default: doc.modified_by,
			read_only: 1,
		},
		{
			fieldtype: 'Section Break'
		},
	]
	if(in_list(['Sales Order','Purchase Order','Material Request'],doc.doctype)) {
		child_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'item_code',
				label: __('Name'),
				in_list_view: 1,
				read_only: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'item_name',
				label: __('Item Name'),
				in_list_view: 1,
				reqd: 1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'brand',
				label: __('Brand'),
				in_list_view: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'model_type',
				label: __('Model Type'),
				in_list_view: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Data',
				fieldname: 'uom',
				label: __('UOM'),
				in_list_view: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Float',
				fieldname: 'qty',
				label: __('Qty'),
				in_list_view: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'rate',
				label: __('Rate'),
				in_list_view: 1,
				columns:1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'amount',
				label: __('Amount'),
				in_list_view: 1,
				columns:1,
			},
		]

		

		group_fields = [
			{
				fieldtype: 'Table',
				fields:child_fields,
				fieldname: 'items',
				label: __('Items'),
				data:doc.items,
				cannot_delete_rows:1,
				cannot_add_rows: 1,
			}
		]

		if(doc.taxes) {
			group_fields.push(
				{
					fieldtype: 'Section Break'
				},
			)
			group_fields.push(
				{
					fieldtype: 'Data',
					fieldname: 'taxes_and_charges',
					label: __('Taxes and Charges'),
					in_list_view: 1,
					reqd: 1,
					default:doc.taxes_and_charges || '',
					read_only: 1,
				},
			)
			group_fields.push(
				{
					fieldtype: 'Table',
					fields:[
						{
							fieldtype: 'Data',
							fieldname: 'charge_type',
							label: __('Charge Type'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Data',
							fieldname: 'account_head',
							label: __('Account Head'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'rate',
							label: __('Tax Rate'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'tax_amount',
							label: __('Tax Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'taxes',
					label: __('Taxes'),
					data:doc.taxes,
					cannot_delete_rows:1,
					cannot_add_rows: 1,
				}
			)
		}
	} else if (in_list(['Expense Claim'],doc.doctype)) {
		child_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'expense_type',
				label: __('Expense Type'),
				in_list_view: 1,
				read_only: 1,
				reqd: 1,
				columns:1,
			},
			{
				fieldtype: 'Text Editor',
				fieldname: 'description',
				label: __('Description'),
				in_list_view: 1,
				reqd: 1,
			},
			{
				fieldtype: 'Currency',
				fieldname: 'amount',
				label: __('Amount'),
				in_list_view: 1,
				columns:1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'invoice_recognition',
				label: __('Invoice Recognition'),
				options: 'Invoice Recognition',
				in_list_view: 1,
				columns:1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'e_invoice',
				label: __('E Invoice'),
				options: 'E Invoice',
				in_list_view: 1,
				columns:1,
				read_only: 1,
			},
			{
				fieldtype: 'Link',
				fieldname: 'invoice_recognition_group',
				label: __('Invoice Recognition Group'),
				options: 'Invoice Recognition Group',
				in_list_view: 1,
				columns:1,
				read_only: 1,
			},
		]
		if(in_list(doc.project,'市场开拓')) {
			child_fields.push(
				{
					fieldtype: 'Data',
					fieldname: 'opportunity_description',
					label: __('Opportunity Description'),
					in_list_view: 1,
					reqd: 1,
				}
			)
		}

		group_fields = [
			{
				fieldtype: 'Table',
				fields:child_fields,
				fieldname: 'expenses',
				label: __('Expenses'),
				data:doc.expenses,
				cannot_add_rows: 1,
				cannot_delete_rows:1,
				readonly:1,
			}
		]

		if(doc.taxes?.length > 0) {
			group_fields.push(
				{
					fieldtype: 'Table',
					fields:[
						{
							fieldtype: 'Data',
							fieldname: 'account_head',
							label: __('Account Head'),
							in_list_view: 1,
							reqd: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'rate',
							label: __('Tax Rate'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'tax_amount',
							label: __('Tax Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'taxes',
					label: __('Taxes'),
					data:doc.taxes,
					cannot_delete_rows:1,
					cannot_add_rows: 1,
				}
			)
		}

		let ir = 0
		data.doc.expenses.forEach(expense => {
			console.log(expense)
			if (expense.invoice_recognition || expense.e_invoice) {
				ir += 1
				console.log(ir)
			}
		})
		if(ir > 0) {
			group_fields.push(
				{
					fieldtype: 'Button',
					fieldname: 'preview',
					label: __('Invoice')+ __('Preview') + `(${ir})`,
					in_list_view: 1,
					click: function(e) {
						preview_all_invoice(data.doc)
					}
				},
			)
		} else {
			msg=`
				<p style="margin:20px">未检测到发票关联</p>
				<p style="margin:20px;">当前发票状态为：<span style="font-weight:bold;font-size:x-large">${data.doc.invoice_status}</span></p>
				<p style="margin:20px">请确认是否为无票报销</p>
			`
			frappe.msgprint(msg)
		}

		if(doc.advances?.length > 0) {
			group_fields.push(
				{
					fieldtype: 'Table',
					fields:[
						{
							fieldtype: 'Link',
							fieldname: 'employee_advance',
							label: __('Employee Advance'),
							options: 'Employee Advance',
							in_list_view: 1,
						},
						{
							fieldtype: 'Date',
							fieldname: 'posting_date',
							label: __('Posting Date'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'advance_paid',
							label: __('Advance Paid'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'unclaimed_amount',
							label: __('Unclaimed Amount'),
							in_list_view: 1,
						},
						{
							fieldtype: 'Currency',
							fieldname: 'allocated_amount',
							label: __('Allocated Amount'),
							in_list_view: 1,
						},
					],
					fieldname: 'advances',
					label: __('Advances'),
					data:doc.advances,
					cannot_delete_rows:1,
					cannot_add_rows: 1,
				}
			)
		}

		
	} else if (in_list(['Payment Application'],doc.doctype)) {
		group_fields = [
			{
				fieldtype: 'Data',
				fieldname: 'transaction_type',
				label: __('Transaction Type'),
				read_only: 1,
				default: doc.transaction_type
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'journal_entry_template',
				label: __('Journal Entry Template'),
				read_only: 1,
				default: doc.journal_entry_template
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'party',
				label: __('Party'),
				read_only: 1,
				default: doc.party
			},
		]
	} else if (in_list(['Payment Request'],doc.doctype)) {
		group_fields = [
			{
				fieldtype: 'Link',
				fieldname: 'reference_doctype',
				options: 'DocType',
				label: __('Reference Doctype'),
				read_only: 1,
				default: __(doc.reference_doctype)
			},
			{
				fieldtype: 'Dynamic Link',
				fieldname: 'reference_name',
				options: 'reference_doctype',
				label: __('Reference Name'),
				read_only: 1,
				default: doc.reference_name
			},
			{
				fieldtype: 'Column Break'
			},
			{
				fieldtype: 'Data',
				fieldname: 'pay_to_employee',
				label: __('Pay To Employee'),
				read_only: 1,
				default: doc.pay_to_employee,
				depends_on: "eval:doc.pay_to_employee",
				hidden:1
			},
			{
				fieldtype: 'Data',
				fieldname: 'payee_name',
				label: __('Payee Name'),
				read_only: 1,
				default: doc.payee_name,
				depends_on: "eval:doc.pay_to_employee",
				description:"付款给个人"
			},
			
		]
	}
	fields.push(...group_fields)


	let workflow_comment_fields = [
		{
			fieldtype: 'Datetime',
			fieldname: 'modified',
			label: __('Modified'),
			read_only: 1,
			in_list_view: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'content',
			label: __('Content'),
			read_only: 1,
			in_list_view: 1,
		},
		{
			fieldtype: 'Data',
			fieldname: 'first_name',
			label: __('First Name'),
			read_only: 1,
			in_list_view: 1,
		},
	]
	
	fields.push(
		{
			fieldtype: 'Section Break'
		},
	)
	fields.push(
		{
			fieldtype: 'Table',
			fields:workflow_comment_fields,
			fieldname: 'workflow_comment',
			label: __('Workflow') + __('Activity'),
			data:data.workflow_comment,
			cannot_add_rows: 1,
			cannot_delete_rows:1,
		}
	)
	
	if(doc.remarks || doc.remark) {
		fields.push(
			{
				fieldtype: 'Section Break'
			},
		)
		fields.push(
			{
				fieldtype: 'Data',
				fieldname: 'remarks',
				label: __('Remarks'),
				default: doc.remarks || doc.remark || '',
				read_only: 1,
			},
		)
	}

	let actions_feilds = [
		{
			fieldtype: 'Section Break',
			label: __('Workflow Action'),
		},
		{
			fieldtype: 'HTML',
			fieldname: 'actions',
			options:`
				<div id = 'actions' class="flex flex-wrap align-items-center justify-content-center"  data-doctype="${doc.doctype}" data-name="${doc.name}"></div>
			`
		},
	]
	fields.push(...actions_feilds)

	return fields
}


function preview_all_invoice (doc) {
	frappe.call({
		method:'localization.localization.overrides.expense_claim.get_invoice_html',
		args:{
			docname:doc.name
		},
		callback:function(r){
			var d = new frappe.ui.Dialog({
				title:__("Invoice Recognition"),
				fields: [
					{
						label:__("Preview"),
						fieldtype:"HTML",
						fieldname:"preview_html",
						options: r.message,
					}
				],
				primary_action_label: "关闭",
				primary_action: function() {
					d.hide()
				}
			})
			
			d.show();
			d.$wrapper.find('.modal-dialog').css("max-width", "90%");
		}
	})
};

function parseElement(element) {
    let obj = {};
    let children = Array.from(element.children);

    children.forEach(child => {
        let key = child.nodeName;
        // 检查是否已经存在该键，如果存在并且是对象，则将其转换为数组
        if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            obj[key] = [obj[key]]; // 转换为数组
        }

        // 如果是数组，直接添加新元素
        if (Array.isArray(obj[key])) {
            obj[key].push(parseElement(child));
        } else {
            // 否则，正常添加或覆盖
            obj[key] = child.children.length > 0 ? parseElement(child) : child.textContent.trim();
        }
    });

    return obj;
}

frappe.ei = function (xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    return parseElement(xmlDoc.documentElement);
}

frappe.workbench = function(el) {
	const app = new Vue({
        el: el,
        data: {
			is_system_manager:false,
			// 控制显示哪些大类和单据类型
			titles:[
				{
					id:'selling',
					svg:[
							{
								d:"M416 294.4l144-211.2 227.2 112L960 838.4l-371.2 99.2L416 294.4z", fill:"#F2AE24"
							},
							{
								d:"M348.8 281.6l128-192-240 96L64 828.8l432 115.2 16-54.4z", fill:"#F2AE24"
							},
							{
								d:"M608 268.8m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z", fill:"#FCEED2"
							}
						],
					config:{
						label: __('Selling'),
						doctypes:['Sales Order','Sales Invoice','Delivery Note','Lead','Opportunity'],
					},
				},
				{
					id:'buying',
					svg:[
						{
							d:"M298.5 691.8l11.2 71.2c2.8 17.5 17.8 30.3 35.5 30.3H358c9.2-13.9 24.9-23.1 42.8-23.1s33.7 9.2 42.8 23.1H708c9.2-13.9 24.9-23.1 42.9-23.1 17.9 0 33.7 9.2 42.8 23.1h4.4c19.8 0 35.9-16.1 35.9-35.9 0-19.8-16.1-35.9-35.9-35.9H375.9l-4.7-29.7h-72.7zM314 328.6l-0.9-5.5-15.9-100.7c-2.5-15.9-15.3-28.2-31.4-30.1l-163.3-19c-19.8-2.2-37.6 11.8-39.9 31.6-2.3 19.7 11.8 37.6 31.6 39.8l136.2 15.8 10.9 69 42.9-0.5 29.8-0.4z",
							fill:"#FF7C20"
						},
						{
							d:"M885 321.4l-571 7.2L370.3 686l0.9 5.8h447.3c19.1 0 35.7-12.6 40.5-30.7l67.1-288.9c6.8-26-13.6-51.2-41.1-50.8z",
							fill:"#99C236"
						},
						{
							d:"M314 328.6l-29.8 0.4-42.8 0.5 0.1 0.6 56 355.5 1 6.2H371.2l-0.9-5.8z",
							fill:"#FFBA3B"
						},
						{
							d:"M349.5 821.6c0 28.4 23 51.3 51.3 51.3s51.3-23 51.3-51.3c0-10.4-3.2-20.1-8.5-28.2H358c-5.4 8.1-8.5 17.7-8.5 28.2zM400.8 770.2c-17.9 0-33.7 9.2-42.8 23.1h85.7c-9.2-13.9-24.9-23.1-42.9-23.1zM699.5 821.6c0 28.4 23 51.3 51.3 51.3 28.4 0 51.3-23 51.3-51.3 0-10.4-3.2-20.1-8.5-28.2H708c-5.3 8.1-8.5 17.7-8.5 28.2zM750.9 770.2c-17.9 0-33.7 9.2-42.8 23.1h85.7c-9.3-13.9-25-23.1-42.9-23.1z",
							fill:"#FF7C20"
						}
					],
					config:{
						label: __('Buying'),
						doctypes:['Purchase Order','Purchase Invoice','Purchase Receipt','Stock Entry'],
					},
				},
				{
					id:'stock',
					svg:[{d:`M398.69642857 558.6558642h229.55357143c10.44642858 0 18.92857143-8.48214286 18.92857143-18.92857143s-8.48214286-18.92857143-18.92857143-18.92857143H398.69642857c-10.44642858 0-18.92857143 8.48214286-18.92857142 18.92857143s8.48214286 18.92857143 18.92857143 18.92857143z m491.78571429-179.10714287c-0.08928572-0.08928572 0-0.26785715-0.08928572-0.35714285L770.66071429 151.95943562c-3.12500001-6.60714286-9.82142857-10.80357143-17.14285715-10.80357143h-484.82142856c-7.32142857 0-14.01785715 4.19642858-17.14285716 10.80357143L135.39285714 379.19157847c-0.08928572 0.08928572 0 0.26785715-0.08928572 0.35714287-1.07142857 2.41071429-1.78571429 5-1.78571428 7.76785714V861.06657848c0 10.44642858 8.48214286 18.92857143 18.92857144 18.92857143h720.80357142c10.44642858 0 18.92857143-8.48214286 18.92857143-18.92857143V387.31657848c0.08928572-2.76785715-0.62500001-5.35714286-1.69642858-7.76785715zM531.82142857 179.10229276h209.73214286l101.69642857 189.28571429H531.82142857v-189.28571429z m-251.16071429 0h213.21428573v189.28571429H182.53571429l98.125-189.28571429z m573.66071429 662.94642858H171.46428571v-435.71428571h682.85714286v435.71428571z`,
						fill:'#007FFF'
					}],
					config:{
						label: __('Stock'),
						doctypes:['Stock Entry','Delivery Note','Purchase Receipt'],
					}
				},
				{
					id:'accounting',
					svg:[
						{
							d:`M877.071386 263.084927H146.563725c-44.273192 0-80.518826 34.664449-82.95142 78.329493v541.008671c2.432593 43.665043 38.678228 78.329493 82.95142 78.329492H877.314645c45.854377 0 83.073049-37.218672 83.07305-83.073049v-531.521558c-0.12163-45.854377-37.461931-83.073049-83.316309-83.073049zM644.393871 683.193728c9.122224 0 16.541632 7.419408 16.541632 16.541632s-7.419408 16.541632-16.541632 16.541633H516.439482V844.109752c0 9.122224-7.419408 16.541632-16.541632 16.541632s-16.541632-7.419408-16.541632-16.541632V716.276993H355.401829c-9.122224 0-16.541632-7.419408-16.541632-16.541633 0-9.000594 7.419408-16.541632 16.541632-16.541632h127.954389v-39.043116H355.401829c-9.122224 0-16.541632-7.419408-16.541632-16.541632s7.419408-16.541632 16.541632-16.541632h114.575128l-104.601497-167.362395c-4.743556-7.784297-2.432593-18.122817 5.351705-22.866374 7.784297-4.986816 18.122817-2.554223 22.866373 5.230075l106.425942 170.159877 106.425941-170.159877c4.986816-7.784297 15.082076-10.21689 22.866374-5.230075 8.027557 4.743556 10.21689 15.203706 5.351704 22.866374l-104.601496 167.362395h114.575127c9.122224 0 16.541632 7.419408 16.541632 16.541632s-7.419408 16.541632-16.541632 16.541632H516.439482v39.043116h127.954389zM113.237202 196.67514h763.834184c9.122224 0 16.541632-7.419408 16.541632-16.541632s-7.419408-16.541632-16.541632-16.541632h-763.834184c-9.122224 0-16.541632 7.419408-16.541632 16.541632 0 9.000594 7.419408 16.541632 16.541632 16.541632zM179.646989 97.060458h631.136239c9.122224 0 16.541632-7.419408 16.541632-16.541632s-7.419408-16.541632-16.541632-16.541632H179.646989c-9.122224 0-16.541632 7.419408-16.541632 16.541632 0 9.000594 7.419408 16.541632 16.541632 16.541632z`,
							fill:'#007FFF'
						}
					],
					config:{
						label: __('Accounting'),
						doctypes:['Payment Entry','Journal Entry','Payment Application','Payment Request'],
					}
				},
				{
					id:'hrms',
					svg:[
						{
							d:`M33.645714 422.326857c0-78.262857 63.926857-142.336 142.336-142.336s142.482286 64.073143 142.482286 142.336c0 78.409143-64.073143 142.482286-142.482286 142.482286S33.645714 500.736 33.645714 422.326857z m226.304 194.413714c-11.995429 30.427429-19.163429 64.073143-19.163428 100.059429v247.954286H0V769.609143c0-96.841143 79.140571-175.981714 175.250286-175.981714 31.158857 0 59.099429 8.777143 84.699428 23.113142z`,
							fill:'#1870FD'
						},
						{
							d:"M668.847543 559.133257c24.722286 23.990857 42.276571 54.418286 53.540571 88.064-11.264-33.645714-28.818286-64.073143-53.540571-88.064z m-157.696-65.536c61.586286 0 116.882286 24.868571 157.696 65.536-40.082286-40.667429-96.109714-65.536-157.696-65.536z m-210.358857 153.6c11.264-33.645714 29.549714-64.073143 53.540571-88.064-24.722286 23.990857-42.276571 54.418286-53.540571 88.064z m421.595428 0c7.168 22.381714 11.995429 45.641143 11.995429 69.632 0-24.868571-4.827429-47.981714-11.995429-69.632z",
							fill:"#F2F2F2"
						},
						{
							d:"M511.151543 444.021029c-99.181714 0-180.809143-80.749714-180.809143-180.809143s80.896-180.809143 180.809143-180.809143c100.059429 0 180.809143 80.749714 180.809143 180.809143s-80.749714 180.809143-180.809143 180.809143z m-3.949714 321.536c0 80.896 36.864 151.990857 95.232 199.241142h-313.636572v-247.954285c0-24.868571 4.827429-47.981714 11.995429-69.632 11.264-33.645714 29.549714-64.073143 53.540571-88.064 40.813714-40.813714 96.109714-65.536 157.696-65.536 50.322286 0 95.963429 16.822857 133.558857 44.763428-82.358857 42.422857-138.386286 128-138.386285 227.181715z",
							fill:"#1870FD"
						},
						{
							d:"M911.945143 548.864c46.518857-23.259429 77.677714-71.241143 77.677714-126.537143 0-78.262857-63.195429-142.336-142.482286-142.336-78.262857 0-142.336 63.195429-142.336 142.336 0 32.036571 10.386286 60.854857 27.940572 84.845714 11.995429-1.609143 23.259429-2.340571 35.254857-2.340571 53.540571 0 103.277714 15.945143 143.945143 44.032z",
							fill:"#1870FD"
						},
						{
							d:"M832.424229 873.3696l39.643428-39.643429 115.858286 116.004572-39.497143 39.643428z m-97.28-304.566857c94.500571 0 170.422857 76.068571 169.691428 169.545143 0 93.622857-76.068571 169.691429-169.691428 169.691428-93.476571 0-169.545143-76.068571-169.545143-169.691428 0-93.476571 76.068571-169.545143 169.545143-169.545143z m72.850285 130.340571l-17.554285-14.336a4.929829 4.929829 0 0 0-5.705143 0.731429l-37.595429 47.250286-8.777143-7.168-18.432-14.482286c-1.462857-0.731429-3.949714-0.731429-4.681143 0.877714l-5.705142 6.436572-7.168 8.777142-39.936 50.322286c-0.877714 1.609143-0.877714 4.096 0.731428 4.827429l18.432 14.482285c1.609143 0.731429 3.949714 0.731429 4.827429-0.877714l37.595428-47.981714 9.508572 7.168 17.700571 14.482286h0.731429c1.609143 0.731429 4.096 0.731429 4.827428-0.877715v-0.731428l51.931429-65.682286c0.877714-1.609143 0.877714-3.949714-0.731429-3.218286z",
							fill:"#AEC9FF"
						}
					],
					config:{
						label: __('HR'),
						doctypes:['Employee Checkin','Leave Application','Checkin Patch','Travel Request','Attendance Request','Attendance','Employee Advance','Expense Claim'],
					}
				},
			],
			doctypes:[],
			current_doctype:'',

			select_fields:{},
			selectedOption:{},
			select_fields_labels:{},

			doc_list:[],
			list_count:0,
			total_list_count:0,
			current_fields:[],
			current_labels:[],
			current_settion:'',
			doctype_config:{
				'Sales Order':{
					dcotype:'Sales Order',
					filters:[['docstatus','=',1],['status','not in',['Closed', 'Completed']]],
					order_by_field:'transaction_date',
					order_by_fields:{
						'transaction_date': 'Transaction Date',
						'customer': 'Customer',
						'project': 'Project'
					},
					order_by_sort:'desc',
				},
				'Sales Invoice':{
					dcotype:'Sales Invoice',
					filters:[['docstatus','=',1],['status','not in',['Paid', 'Completed']]],
					
				},
				'Delivery Note':{
					dcotype:'Delivery Note',
					filters:[['docstatus','=',1],['status','not in',['Completed', 'Return','Closed','Ignore Sales Invoice']]],
				},
				'Lead':{
					filters:[['status','not in',['Closed']]],
				},
				'Attendance':{
					filters:[['status','not in',['Present','On Leave']]]
				}
			},
			page_length:[
				20,100,500,2500
			],
			current_page_length:20,
			total_doc_count:0,
			status_color_map : {
				// Comment
				'Completed':'green',
				'Complete':'green',
				'Open':'green',
				'Closed':'green',
				'Draft':'pink',
				'Pending':'orange',
				'Cancelled':'red',
				// Stocking
				'On Hold':'yellow',
				'Overdue': 'red',
				'To Deliver and Bill':'purple',
				'To Receive and Bill':'purple',
				'To Bill':'pink',
				'To Deliver':'orange',
				'To Receive':'orange',
				'Delivered':'green',
				'Partially Ordered':'yellow',
				'Partially Received':'purple',
				'Ordered': 'green',
				'Ignore Sales Invoice':'green',
				'Return Issued':'purple',
				// accounting
				'Partly Paid': 'green',
				'Paid': 'blue',
				'Requested':'green',
				'Initiated':'pink',
				'Partially Paid':'orange',
				'To Be Claimed':'purple',
				'Complete By Special Funds': 'dark blue',
				'Unpaid': 'purple',
				'Not Started':'orange',
				'Claimed': 'green',
				'Returned': 'green',
				'Partly Claimed and Returned': 'green',
				// Attendance
				'Absent': 'red',
				'Half Day': 'orange',
				// CRM
				'Lost Quotation': 'pink',
				'Opportunity': 'purple',
				'Converted': 'green',

			},
			isModalVisible: false, // 控制模态框的显示与隐藏
			modalContent: '', // 模态框的内容
			meta:'',
			child_fields:{},
			doc:{},
			tables:[],
			user_name:{},
		},
		methods: {
			show(item,index){
				this.doctypes = item.config.doctypes
				this.doc_list = []
				this.current_doctype = ''
				this.getQueryParameters()
				this.current_settion = item.id
				this.current_page_length = 20

			},
			get_list(doctype){
				let that = this
				let fields = []
				let labels = []
				let fields_config = []
				let all_fields=[]
				let filters = []
				let inlist_fields = [
					'remark','status',
					'remarks','project',
					'customer','supplier',
					'company','party',
					'posting_date','transaction_date',
					'workflow_state','docstatus'
					]
				if(!doctype) {
					doctype = this.current_doctype
				} else if (doctype != this.current_doctype) {
					this.current_doctype = doctype
					this.init_doctype_config()
				}

				frappe.model.with_doctype(doctype, function () {
					let meta = frappe.get_meta(doctype);
					that.meta = meta
					that.set_select_fields(meta)
					meta.fields.forEach((value) => {
						all_fields.push(value.fieldname)
						if (!["Section Break", "Column Break"].includes(value.fieldtype) && (value.in_list_view == 1 || in_list(inlist_fields,value.fieldname))) {
							fields.push(value.fieldname);
							labels.push(value.label);
							fields_config.push(value)
						}
					});
					// 添加单据名称
					fields.unshift('name')
					labels.unshift('Name')
					fields_config.unshift({fieldname:'name',label:'Name'})
					// 添加创建和修改人信息等
					let add_fields = [
						{fieldname:'owner',label:'Owner'},
						{fieldname:'creation',label:'Creation',fieldtype:'Datetime'},
						{fieldname:'modified_by',label:'Modified By'},
						{fieldname:'modified',label:'Last Updated On',fieldtype:'Datetime'},]
					add_fields.forEach(field => {
						fields.push(field.fieldname)
						labels.push(field.label)
						fields_config.push(field)
					})

					that.current_fields = fields_config
					that.current_labels = labels
					
					let order_by = that.doctype_config[doctype].order_by_field + ' ' +  that.doctype_config[doctype].order_by_sort || 'modified desc'
					// console.log({
					// 	filters:that.doctype_config[doctype].filters,
					// 	and_filters:that.doctype_config[doctype].and_filters
					// })
					filters = [...that.doctype_config[doctype].filters,...that.doctype_config[doctype].and_filters]
					// console.log({
					// 	filters:filters
					// })
					frappe.db.get_list(doctype,
						{
							fields: fields || {},
							filters:filters,
							limit:that.current_page_length,
							order_by:order_by || 'modified desc'
						}
					)
						.then((res) => {
							that.doc_list = res
							that.list_count = res.length	
							// console.log(res)
						})
				});
				this.get_count(filters)
			},
			set_page_length(length) {
				this.current_page_length = length
				this.get_list(this.current_doctype)
			},
			format_currency(v, currency, decimals) {
				const format = get_number_format(currency);
				const symbol = get_currency_symbol(currency);
				const show_symbol_on_right =
					frappe.model.get_value(":Currency", currency, "symbol_on_right") ?? false;
			
				if (decimals === undefined) {
					decimals = frappe.boot.sysdefaults.currency_precision || null;
				}
			
				if (symbol) {
					if (show_symbol_on_right) {
						return format_number(v, format, decimals) + " " + __(symbol);
					}
					return __(symbol) + " " + format_number(v, format, decimals);
				}
			
				return format_number(v, format, decimals);
			},
			getQueryParameters() {
				// 获取查询字符串部分
				const queryString = window.location.search;
				// 去除查询字符串前面的"?"
				const urlParams = new URLSearchParams(queryString.substring(1));
				// 创建一个空对象来存储查询参数
				const params = {};
				// 遍历查询参数
				for (let [key, value] of urlParams.entries()) {
				// 将查询参数添加到对象中
				params[key] = value;
				}
				// console.log(params)
				return params;
			},
			get_form_link(doctype,name) {
				return `/app/${encodeURIComponent(
					frappe.router.slug(frappe.router.doctype_layout || doctype)
				)}/${encodeURIComponent(cstr(name))}`;
			},
			show_modal(item){
				// console.log(item)
				let that = this
				this.buttonText = item.name;
				let tables=[]
			    let promises = []; // 用于存储所有的 Promise
				// let child_field_names = {}
				this.meta.fields.forEach((value) => {
					if (value.fieldtype == 'Table') {
						// child_field_names[value.options] = []
						tables.push({
							name:value.fieldname,
							label:value.label
						});
						
						frappe.model.with_doctype(value.options, function () {
							let child_meta = frappe.get_meta(value.options);
							that.child_fields[value.fieldname] = []
							
							child_meta.fields.forEach((child_field) => {
								if (child_field.in_list_view == 1) {
									that.child_fields[value.fieldname].push(child_field)
								}
							})
						})
					}
					that.tables = tables
					
				});
				if(that.tables.length == 0) {
					return
				}
				frappe.db.get_doc(that.current_doctype, item.name)
							.then((res) => {
								that.doc = res
								that.isModalVisible = true;
								this.setupModalClose();
							});

			},
			hideModal() {
				this.isModalVisible = false;
				// 可以在这里添加关闭模态框时的其他逻辑

				// 移除点击事件监听器（可选，如果你只在打开模态框时添加它）
				document.removeEventListener('click', this.handleOutsideClick);
			},
			setupModalClose() {
				// 只在需要时添加监听器，例如只在模态框打开时
				document.addEventListener('click', this.handleOutsideClick);
			},
			handleOutsideClick(event) {
				// 检查点击事件是否发生在模态框或其子元素之外
				const modal = this.$el.querySelector('.modal');
				if (modal && event.target !== modal && !modal.contains(event.target)) {
					this.hideModal();
				}
			},
			set_order_by_field(order_by_field) {
				this.doctype_config[this.current_doctype].order_by_field = order_by_field
				this.get_list(this.current_doctype)
			},
			toggle_order_by_sort() {
				let current_sort = this.doctype_config[this.current_doctype].order_by_sort
				if (!current_sort || current_sort == 'desc') {
					this.doctype_config[this.current_doctype].order_by_sort = 'asc'
				} else {
					this.doctype_config[this.current_doctype].order_by_sort = 'desc'
				}

				this.get_list(this.current_doctype)
			},
			init_doctype_config() {
				let that = this
				this.titles.forEach((item)=>{
					let doctypes = item.config.doctypes
					doctypes.forEach((doctype)=>{
						if (!that.doctype_config[doctype]) {
							that.doctype_config[doctype] = {
								order_by_field:'modified',
								order_by_sort:'desc',
								order_by_fields:{
									'modified': 'Last Updated On',
									'creation': 'Created On'
								},
								filters:[],
								and_filters:[]
							}
						} else {
							if (!that.doctype_config[doctype].order_by_field) {
								that.doctype_config[doctype].order_by_field = 'modified'
							} 
							if (!that.doctype_config[doctype].order_by_sort) {
								that.doctype_config[doctype].order_by_sort = 'desc'
							}
							if (!that.doctype_config[doctype].order_by_fields) {
								that.doctype_config[doctype].order_by_fields = {
									'modified': 'Last Updated On',
								}
							} else {
								that.doctype_config[doctype].order_by_fields['modified'] = 'Last Updated On'
								that.doctype_config[doctype].order_by_fields['creation'] = 'Created On'
							}
						}
						that.doctype_config[doctype]['and_filters'] = []
						// 增加权限判断标志
						that.doctype_config[doctype]['has_perm'] = frappe.model.can_read(doctype)
					})
				})
				this.selectedOption = {}
				this.select_fields = {}
			},
			set_select_fields(meta) {
				if(!this.meta) return
				let that = this
				let fields = []
				let com_fields = ['company','project','employee','customer','supplier']
				meta.fields.forEach((field)=>{
					if(field.fieldtype == 'Select' && field.in_standard_filter == 1){
						if(typeof field.options === "string") {
							field.options = field.options.split('\n')
							if (!field.options.includes('')) {
								field.options.unshift('')
							}
						}
						
						fields.push(field.fieldname)
						// that.select_fields[field.fieldname] = field.options
					} else if(field.fieldtype == 'Link' && in_list(com_fields,field.fieldname) ) {
						// frappe.db.get_list(field.options,{limit:100}).then((res) => {
						// 	if (res.length > 0) {
						// 		let link_data = ['']
						// 		res.forEach(link => {
						// 			link_data.push(link.name)
						// 		})
								fields.push(field.fieldname)
						// 		that.select_fields[field.fieldname] = link_data
						// 	}
							
						// })
					}
					
				})
				// console.log(fields)
				frappe.call({
					method:"localization.localization.utils.api.get_list_filters",
					args:{
						doctype:that.current_doctype,
						fields:fields,
						user:frappe.session.user
					},
					callback:(res)=>{
						// console.log(res)
						if(res.message){
							that.select_fields = res.message.filters
							that.select_fields_labels = res.message.labels
						}
					}
				})
			},
			set_filters(field_name){
				let option = this.selectedOption[field_name]
				let and_filters = []
				this.doctype_config[this.current_doctype]['and_filters'] = []

				for (let key in this.select_fields) {
					if (this.selectedOption[key]) {
						and_filters.push([key,'=',this.selectedOption[key]])
						// console.log({and_filters:and_filters})
					}
				}
				this.doctype_config[this.current_doctype]['and_filters'] = and_filters
				this.get_list()
			},
			get_user_info(user){
				let that = this
				frappe.call({
					method:"localization.localization.utils.api.get_user_names",
					args:{},
					callback:function(res){
						if(res.message){
							res.message.forEach((user)=>{
								that.user_name[user.name] = user.first_name
							})
						}
					}
				})
			},
			view_more(){
				this.current_page_length = this.current_page_length + 20
				this.get_list()
			},
			get_count(filters){
				if(!this.current_doctype) return
				let that = this
				frappe.db.count(this.current_doctype,{
					filters:filters || []
				})
					.then(r=>{
						that.total_list_count = r
					})
			}
		},
		mounted() {
			this.init_doctype_config()
			this.$el.style.display = "block"
			this.get_user_info()

			if(frappe.user.has_role('System Manager') || frappe.user.has_role('Super User')){
				this.is_system_manager = true
			}
		}
	})
}

frappe.sync_items_with_attachments = function(frm){
	this.attachments = frm.attachments.get_attachments()

	if (this.attachments.length > 0){

		frm.set_df_property('items','hidden',0)
		frm.set_df_property('project','hidden',0)
		frm.set_df_property('expense_type','hidden',0)
	} else {
		frm.set_df_property('items','hidden',1)
		frm.set_df_property('project','hidden',1)
		frm.set_df_property('expense_type','hidden',1)
	}

	let file_list = this.attachments.map(file => file.name)
	let file_dict = this.attachments.reduce((acc, file) => {
		acc[file.name] = file
		return acc
	}, {})

	let exsits_file_list = frm.doc.items.map(item => item.file)
	let set_invoice = new Set(file_list)
	let set_doc_items = new Set(exsits_file_list)
	let new_invoice_list = Array.from(set_invoice).filter(file => !set_doc_items.has(file))
	let removed_invoice_list = Array.from(set_doc_items).filter(file => !set_invoice.has(file))
	if(new_invoice_list.length == 0 &&  removed_invoice_list.length == 0) {
		return
	}

	// return
	new_invoice_list.forEach(file => {
		if(!in_list(exsits_file_list,file)){
			var row = frappe.model.add_child(frm.doc, "Invoice Recognition Group Items", "items");
			frappe.model.set_value(row.doctype, row.name, "file", file)
			frappe.model.set_value(row.doctype, row.name, "file_url", file_dict[file].file_url)
		}
	});
	frm.doc.items.forEach(item => {
		if(in_list(removed_invoice_list,item.file)) {
			frappe.model.remove_from_locals("Invoice Recognition Group Items", item.name);
		}
	})
	frm.set_value('total_qty',this.attachments.length || 0)

	refresh_field("items");
	frm.dirty()
	frm.enable_save()
}