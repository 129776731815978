import { reactive,createApp, ref,onMounted  } from 'vue';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import 'element-plus/dist/index.css'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

frappe.funds_distribution = class FundsDistribution {
	constructor({wrapper},page) {
		this.wrapper = wrapper;
		this.page = page;
		this.init()
	}
	init() {
		this.wrapper[0].innerHTML = `
			<div id="funds_distribution">
			</div>`
		const app = createApp(App);
		app.config.devtools = true
		
		// 假设 '#layout-main-section' 是您要挂载 Vue 应用的元素的选择器

		app.use(ElementPlus, {
			locale: zhCn,
		})
		for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
			app.component(key, component)
		}
		app.mount("#funds_distribution");
		// app.mount('.main-section');
		
	}
}