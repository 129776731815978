import { reactive,createApp, ref,onMounted  } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './uwl.vue'
frappe.vue_uwl = class VueUwl {
	constructor(el) {
		this.el = el;
		this.init();
	}

	init() {
		const app = createApp(App);
		app.config.devtools = true
		app.use(ElementPlus, {
			locale: zhCn,
		})
		app.config.devtools = true
		for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
			app.component(key, component)
		}
		app.mount(this.el);
	}
}